import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { LiaUser, LiaUserAltSlashSolid } from "react-icons/lia";
import { useDispatch } from "react-redux";
import { blockUserById, exportCSV, getUserDataLists } from "../apis/user";
import ViewUserDetails from "../components/ViewUserDetails";
import CommonTable from "../components/common/CommonTable";
import CutOffText from "../components/common/CutOffText";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import ActionsModal from "../components/common/Modals/ActionsModal";
import TableAction from "../components/common/TableAction";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";
import { statusData, subscribedData } from "../utils/constant";

const Customer = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [subscribed, setSubscribed] = useState("all" || null);
  const [status, setStatus] = useState("all" || null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [disableUser, setDisableUser] = useState(null);
  const [disableUserModal, setDisableUserModal] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const dateFormat = "YYYY/MM/DD";
  const [date, setDate] = useState(null);
  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;
  const data = {
    filter: subscribed,
    page: currentPage,
    limit: limit,
    searchQuery: searchInput.trim(),
    status: status,
    start_date: date?.length > 0 ? date[0] : null,
    end_date: date?.length > 0 ? date[1] : null,
  };
  const fetchUserData = async () => {
    showLoader();
    dispatch(getUserDataLists(data))
      .then((res) => {
        if (res.status) {
          setUserData(res.data.data.users);
          setLimit(res.data.data.limit);
          setCurrentPage(res.data.data.page);
          setTotalItems(res.data.data.total);
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });
  };
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [limit, currentPage, searchInput, subscribed, status, date]);

  const handleView = (record) => {
    setSelectedUser(record);
    setShowUserDetails(true);
  };

  const handleDisable = (record) => {
    console.log("record", record);
    setDescription("");
    setDisableUser(record);
    setDisableUserModal(true);
    setError(false);
  };

  const handleDisableUser = async () => {
    if (!disableUser.blocked && !description.trim()) {
      setError(true);
      return;
    }
    setDisableUser(null);
    setDisableUserModal(false);
    if (!disableUser) return;

    try {
      const response = await dispatch(
        blockUserById(disableUser._id, disableUser.blocked, description)
      );
      console.log("response", response);
      setDescription("");
      setError(false);
      fetchUserData();
    } catch (err) {
      setDescription("");
      setError(false);
      console.log(err);
    }
  };

  const handleExportCSV = async () => {
    const data = {
      fields: [
        "name",
        "email",
        "gender",
        "sign_up_date",
        "last_login",
        "blocked",
      ],
    };
    try {
      const response = await dispatch(exportCSV(data));
      if (response.status) {
        console.log("response.data", response.data.data);
        window.open(response.data.data, "_blank");
      } else {
        console.log("response", response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name && b.name && a.name.localeCompare(b.name)) || 0,
      render: (text) => <>{<CutOffText maxLength={20} value={text} />}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      sorter: (a, b) =>
        (a.email && b.email && a.email.localeCompare(b.email)) || 0,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text) => <>{!text ? "-" : text}</>,
    },
    {
      title: "Sign Up date",
      dataIndex: "sign_up_date",
      key: "sign_up_date",
      render: (text) => <>{!text ? "-" : text}</>,
    },
    {
      title: "last logged",
      dataIndex: "last_login",
      key: "last_login",
      render: (text) => <>{!text ? "-" : text}</>,
    },
    {
      title: "Status",
      dataIndex: "blocked",
      key: "blocked",
      render: (text, contact) => (
        <span>
          <Space
            className={`inlineFlex items-center px-3 py-1 rounded-[20px] border-none ${
              text === false ? "bg-[#C3F4E5]" : "bg-[#E9EBED]"
            }`}
          >
            {/* <Badge color={`${text === "active" ? "#09AB79" : "#5B6A79"}`} /> */}
            <span className="text-base font-medium hover:text-[#333] capitalize">
              {text === true ? "inActive" : "Active"}
            </span>
          </Space>
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <TableAction view={true} onView={() => handleView(record)} />
          <span
            onClick={() => handleDisable(record)}
            className="flex justify-center item-center"
          >
            {record.blocked ? (
              <LiaUserAltSlashSolid
                title="unBlock"
                color="#59BF87"
                className="text-[#59BF87] cursor-pointer hover:text-[red] h-[24px] w-[24px]"
              />
            ) : (
              <LiaUser
                title="Block"
                color="#59BF87"
                className="text-[#59BF87] cursor-pointer hover:text-[#59BF87] h-[24px] w-[24px]"
              />
            )}
          </span>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setLimit(size);
  };

  return (
    <CustomLayout>
      {showUserDetails ? (
        <ViewUserDetails
          user={selectedUser}
          setShowUserDetails={setShowUserDetails}
        />
      ) : (
        <>
          <CommonTable
            apiData={userData && userData}
            columns={columns}
            currentPage={currentPage}
            itemsPerPage={limit}
            onPageChange={handlePageChange}
            onShowSizeChange={handleSizeChange}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            totalItems={totalItems}
            filter={true}
            showCSV={true}
            onExportCSV={handleExportCSV}
            additionalFilters={[
              // {
              //   defaultValue: subscribed,
              //   value: subscribed,
              //   placeholder: "Select Subscription",
              //   onChange: (value) => setSubscribed(value),
              //   options: subscribedData,
              // },
              {
                defaultValue: status,
                value: status,
                placeholder: "Select Status",
                onChange: (value) => setStatus(value),
                options: statusData,
              },
            ]}
            setDate={setDate}
            dateFilter={true}
            datepickerType="RangePicker"
            dateFormat={dateFormat}
            setCurrentPage={setCurrentPage}
          />
          {disableUserModal && (
            <ActionsModal
              title=""
              type="delete"
              isOpen={disableUserModal}
              onCancel={() => setDisableUserModal(false)}
              onOk={handleDisableUser}
              footer={false}
              centered={true}
              closable={false}
              maskClosable={false}
              className="delete-modal"
              cancelBtnClass="cancleBtnAction"
              cancelBtnClick={() => setDisableUserModal(false)}
              cancelButtonProps="Cancel"
              saveBtnClass="saveBtnAction"
              saveBtnClick={handleDisableUser}
              saveButtonProps={disableUser.blocked ? "UnBlock" : "Block"}
              description={`Do you really want to ${
                disableUser.blocked ? "unblock" : "block"
              } these record?`}
              mainTitle="Are you sure?"
              notes={disableUser.blocked ? false : true}
              enteredNotes={description}
              setNotes={setDescription}
              error={error}
              errorMessage={"Reason is required"}
              setError={setError}
            />
          )}
        </>
      )}
    </CustomLayout>
  );
};

export default withAuth(Customer);
