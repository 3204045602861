import { SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, DatePicker, Input, Select, Table } from "antd";
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import useScreenWidth from "../../../utils/ScreenWidth";
import CommonPagination from "../Pagination";
import CommonButton from "../Button/CommonButton";
const { RangePicker } = DatePicker;

const { Option } = Select;

const CommonTable = ({
  apiData,
  columns,
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onShowSizeChange,
  // pageSizeOptions,
  pagination = true,
  searchInput,
  search = true,
  setSearchInput,
  additionalFilters,
  filter = false,
  showCSV = false,
  onExportCSV,
  setDate,
  dateFilter = false,
  datepickerType = "DatePicker",
  dateFormat = "DD/MM/YYYY",
  setCurrentPage,
}) => {
  const screenWidth = useScreenWidth();
  const [input, setInput] = useState(searchInput);
  const [specificDate, setSpecificDate] = useState(null);
  const pageSizeOptions = [10, 20, 50, 100];

  return (
    <ConfigProvider>
      <div className="">
        {filter && (
          <div className="filterContainer">
            <div className="filter">
              <div className="selectContainer">
                {additionalFilters &&
                  additionalFilters?.map((filter, index) => {
                    return (
                      <Select
                        key={index}
                        style={{ width: "150px" }}
                        // showSearch
                        defaultValue={filter?.defaultValue}
                        value={filter?.value}
                        onChange={filter?.onChange}
                        optionFilterProp="children"
                        className="custom-filter-dropdown"
                        placeholder={filter?.placeholder}
                      >
                        {filter?.options?.map((option, optionIndex) => (
                          <Option
                            key={`option-${optionIndex}`}
                            value={option.value}
                            label={option.label}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        <div className="Container">
          <div className="mainContainer">
            <div className="secondaryContainer">
              {apiData && search && (
                <Input
                  placeholder="Search"
                  prefix={
                    <SearchOutlined onClick={() => setSearchInput(input)} />
                  }
                  value={input}
                  onChange={(e) => setInput(e.target.value.trim())}
                  onPressEnter={() => {
                    setSearchInput(input);
                    setCurrentPage(1);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Backspace") {
                      if (input === "") {
                        e.preventDefault();
                        setSearchInput("");
                        setInput("");
                      }
                    }
                  }}
                  suffix={
                    input && (
                      <AiOutlineCloseCircle
                        className="hover:text-[red] cursor-pointer"
                        onClick={() => {
                          setInput("");
                          setSearchInput("");
                        }}
                      />
                    )
                  }
                />
              )}
            </div>
            {apiData && dateFilter && (
              <>
                {datepickerType === "DatePicker" && (
                  <DatePicker
                    className="h-[36px] ml-[20px]"
                    value={specificDate}
                    onChange={(date, dateString) => {
                      setSpecificDate(date);
                      setDate(dateString);
                      setCurrentPage(1);
                    }}
                    placeholder="Select Date"
                    format={dateFormat}
                  />
                )}
                {datepickerType === "RangePicker" && (
                  <RangePicker
                    className="h-[36px] ml-[20px]"
                    value={specificDate}
                    onChange={(date, dateStrings) => {
                      setSpecificDate(date);
                      setDate(dateStrings);
                      setCurrentPage(1);
                      console.log("date Range Picker", date, dateStrings);
                    }}
                    format={dateFormat}
                    placeholder="Select Date"
                  />
                )}
              </>
            )}
            {showCSV && (
              <div className="exportContainer">
                <CommonButton
                  type="primary"
                  className="commonButton"
                  onClick={onExportCSV}
                >
                  Export to excel{" "}
                </CommonButton>
              </div>
            )}
          </div>

          <div className="maindiv">
            <Table
              dataSource={apiData}
              columns={columns}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record.id}
              scroll={{ x: 1000 }}
            />
          </div>
          {apiData && pagination && (
            <CommonPagination
              className="pagination"
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={onPageChange}
              onShowSizeChange={onShowSizeChange}
              pageSizeOptions={pageSizeOptions}
              showQuickJumper={screenWidth > 1025 ? true : false}
              showSizeChanger={screenWidth > 1025 ? true : false}
            />
          )}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default CommonTable;
